.ant-menu-sub .ant-menu-item {
  margin-bottom: 4px !important;
}

.max-width-1200 {
  max-width: 1200px;
}

.user-panel .ant-drawer-content-wrapper {
  width: 480px !important;
}

.ant-modal-mask {
  background: rgba(18, 19, 36, 0.9) !important;
}

.mega-menu {
  top: 72px !important;
  right: 0 !important;
  left: 0 !important;
  max-width: 900px;
}

.custom-shadow {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15) !important;
}

.mega-menu .ant-menu:not(.ant-menu-hidden) {
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px !important;
}

.user-menu {
  top: 70px !important;
}

.overlay-helper .ant-popover-content .ant-popover-inner {
  max-width: 224px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.table-blocker .ant-spin-spinning {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.legalities .ant-modal {
  max-width: 800px !important;
  width: 100% !important;
}

.legalities {
  display: flex;
  align-items: center;
}

#mobile-accomplish-drawer .ant-drawer-content-wrapper {
  width: 320px !important;
}

#mobile-drawer .ant-drawer-header {
  border: none;
  padding: 24px 16px;
}
#mobile-drawer .ant-menu-item-group-title {
  padding: 0;
}
#mobile-drawer .ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 14px;
}
#mobile-drawer .ant-drawer-body {
  padding: 10px 16px;
}
#mobile-drawer .ant-drawer-close {
  margin-top: 12px;
  color: #000000;
}
#mobile-drawer .ant-menu-submenu-title {
  padding: 0 !important;
  position: relative;
}
#mobile-drawer .ant-menu-submenu-arrow {
  right: unset;
  left: 50%;
}
#mobile-drawer .ant-menu-submenu-title:hover .ant-typography,
#mobile-drawer a:hover .ant-typography,
#mobile-drawer .ant-menu-item:hover .ant-typography {
  color: #675bf5 !important;
}
#mobile-drawer .with-border .ant-drawer-header {
  border-bottom: 1px solid #e8e8e8;
  padding: 16px;
}

#mobile-drawer .rs-101-menu .ant-menu-item {
  /* background-color: tomato; */
  margin: 0;
  height: 100%;
  max-height: 40px;
}
