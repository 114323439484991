/* ibm-plex-sans-regular - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/ibm-plex-sans-v7-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans"), local("IBMPlexSans"),
    url("./fonts/ibm-plex-sans-v7-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/ibm-plex-sans-v7-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/ibm-plex-sans-v7-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/ibm-plex-sans-v7-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/ibm-plex-sans-v7-latin-regular.svg#IBMPlexSans")
      format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-600 - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/ibm-plex-sans-v7-latin-600.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"),
    url("./fonts/ibm-plex-sans-v7-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/ibm-plex-sans-v7-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./fonts/ibm-plex-sans-v7-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/ibm-plex-sans-v7-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/ibm-plex-sans-v7-latin-600.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}

body {
  font-family: IBM Plex Sans;
}

.fs-12 {
  font-size: 12px;
  line-height: 20px;
}

.fs-14 {
  font-size: 14px;
  line-height: 22px;
}

.fs-16 {
  font-size: 16px;
  line-height: 24px;
}

.fs-18 {
  font-size: 18px;
  line-height: 30px;
}

.fs-20 {
  font-size: 20px;
  line-height: 28px;
}

.fs-24 {
  font-size: 24px;
  line-height: 34px;
}

.fs-28 {
  font-size: 28px;
  line-height: 36px;
}


.fs-30 {
  font-size: 30px;
  line-height: 34px;
}

.subheading {
  font-size: 24px;
  line-height: 32px;
}

.text-muted {
  color: #8c8c8c !important;
}

.text-grey-4 {
  color: #e8e8e8;
}

.text-grey-5 {
  color: #d9d9d9;
}

.text-dark {
  color: #262626 !important;
}

.text-danger {
  color: #ff4d4f !important;
}

.text-primary {
  color: #675bf5 !important;
}
