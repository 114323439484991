.bg-primary-dark {
  background: #121324 !important;
}

.bg-primary {
  background: #675bf5 !important;
}

.bg-primary-black {
  background: #14151b !important;
}
